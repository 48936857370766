import cn from 'classnames';
import { useRouter } from 'next/router';
import { Button } from 'ui/components/Button';
import { isValidElement } from 'react';
import { ResponsiveImageWrapper } from 'ui/components/ResponsiveImageWrapper';
import styles from './postPreview.module.scss';

export default function PostPreview({
  layout = 'column',
  image,
  title,
  content,
  blog_url,
  short_snippet,
  className,
  read_more_text,
  url,
  isNoscript = false,
}) {
  const router = useRouter();
  const readMore = 'Read more';

  const truncatedDesc = content?.length >= 113 ? `${content.substr(0, 113)}...` : content;
  return (
    <div onClick={() => router.push(blog_url || url?.url)} rel="noreferrer" target="_blank" className={className}>
      <div className={cn(styles.frame, styles[`frame-${layout}`])}>
        {(isValidElement(image) || (image && image.url)) && (
          <div className={styles.thumb}>
            {isValidElement(image) ? (
              image
            ) : (
              <ResponsiveImageWrapper
                className="bg-img"
                src={image.url}
                alt={image.alternativeText || 'icon'}
                blurHash={image.blurDataURL}
                layout={image.layout || 'intrinsic'}
                width={image.width || 264}
                height={image.height || 162}
                isNoscript={isNoscript}
              />
            )}
          </div>
        )}
        <div className={styles.content}>
          <h3 className={cn(styles.title)}>{title}</h3>
          <div className={styles.desc}>
            <p>{short_snippet || truncatedDesc}</p>
          </div>
          <Button text={read_more_text || readMore} outline size="xs" cta={blog_url || url?.url} className={styles.button} />
        </div>
      </div>
    </div>
  );
}
