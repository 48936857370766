import cn from 'classnames';
import { useRouter } from 'next/router';
import { Button, ButtonSize } from 'ui/components/Button';
import { PrismicNextImage } from '@prismicio/next';
import { asLink, LinkField, ImageField } from '@prismicio/client';
import { FC } from 'react';
import styles from './postPreview.module.scss';

interface PostPreviewProps {
  title: string;
  content: string;
  truncate?: boolean;
  url: LinkField;
  image: ImageField;
  layout?: 'column' | 'row';
  cta?: string;
  className?: string;
}

export const PostPreview: FC<PostPreviewProps> = props => {
  const { layout, image, title, content, url, cta, className, truncate } = props;

  const link = asLink(url);
  const router = useRouter();

  const renderTruncatedDesc = () => {
    return content?.length >= 113 ? `${content.substr(0, 113)}...` : content;
  };

  return (
    <div onClick={() => router.push(link)} className={className}>
      <div className={cn(styles.frame, styles[`frame-${layout}`])}>
        {image && image.url && (
          <div className={styles.thumb}>
            <PrismicNextImage field={image} fill style={{ objectFit: 'cover' }} />
          </div>
        )}
        <div className={styles.content}>
          <h3 className={cn(styles.title)}>{title}</h3>
          <div className={styles.desc}>
            <p>{truncate ? renderTruncatedDesc() : content}</p>
          </div>
          <Button text={cta} outline size={ButtonSize.ExtraSmall} cta={link} className={styles.button} />
        </div>
      </div>
    </div>
  );
};

PostPreview.defaultProps = {
  layout: 'column',
  cta: 'Read more',
  truncate: true,
};
